<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-alert
      type="info"
      color="lime darken-2"
      :value="true"
      class="mb-4"
      outline
    >
      <strong> Peringatan : </strong><br>
      • Setiap kelas yang dianjurkan oleh LEAD Program hanya dibuka untuk 90 peserta sahaja.
      <br>
      • Pastikan semua maklumat yang diberi adalah tepat.
      <br>
      • Jika kurang daripada 10 peserta daftar untuk kelas tersebut, HQ akan batalkan kelas.
      <br>
      • Notifikasi pembatalan kelas akan dibuat melalui SMS, dan SMS akan dihantar mengikut
      nombor telefon yang diberi dalam borang pendaftaran.
      <br>
    </v-alert>
    <v-alert
      color="green darken"
      :value="true"
      class="mb-4"
      outline
    >
      <strong> Cara Pendaftaran :   </strong><br>
      1) Klik <strong>ikon</strong> pada kelas pilihan.
      <br>
      2) Isi borang pendaftaran dan pilih masa yang tepat.
      <br>
      3) Salin atau screenshot MEETING ID dan PASSWORD yang tertera di skrin.
      <td>&nbsp;</td>
      <strong> Cara Masuk Ke Kelas :   </strong><br>
      1) Muat turun aplikasi ZOOM MEETING.
      <br>
      2) 30 minit sebelum kelas bermula, sila buka aplikasi ZOOM MEETING dan pilih JOIN MEETING.
      <br>
      3) Masukkan MEETING ID dan PASSWORD.
    </v-alert>
    <v-card>
      <v-subheader>Classes</v-subheader>
      <v-list-tile>
        <span>DML100 siri (Bahasa Melayu)</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSdzQJwqzfM7Tpv5K1rLwUjnVo3v7ysyM1_2UjifZvzMibMuJA/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile><v-list-tile>
        <span>DML100 siri (Bahasa Mandarin)</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSe1E49BqU8kn8Uf99NmeRog8wzlF0NUn8l-mzFdXyzzJb8FsA/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <span>DML104 Dealer Affiliate Program</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSfnONmX66opATL9QmqaBclU3wOWsyu8wXsZpdU7BtChFlnC4w/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <span>DMC004 Edisi Khas: Kelas ADX</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSeWFAxYyaC3MA9gCD6Igq1_lgMnLgn4SxXTPbftgEYXzKg2Xg/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <span>DMD101 EPP Black Market Training</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSdtrZ-ihgqTB69rQ5faVFDBaPgz-SkmVT-01KHMW_8F2f-ipw/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <span>DML201 Ekspedisi Ke Puncak Kejayaan</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSdvPlp2nb5QRZvHwbev8rdGKKw6E9PD3Rg-IPhXjxEHydYA2Q/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <span>DML202 Digital Facebook Class</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSeXEuFZj229NFTMeSHjb46TSQ3wa7G33fKKD7GGBr4ZA9purQ/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
      <v-list-tile>
        <span>DML301 Digital Facebook Advance Class</span>
        <v-spacer />
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSd22CzS0JPI2lXY9X467rxyeqZuexvE-RhJM_wiOSrECXpDzg/viewform"
          target="_blank"
          flat
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile>
    </v-card>
  </panel>
</template>

<script>

const pageTitle = 'RSVP Lead Classes'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Lead Program', disabled: false, to: '/lead_menu',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      title: pageTitle,
      items: {},
      item: {},
      loading: false,
    }
  },
}
</script>
